import { Ingredient } from "../models/ingredient.model";
import { FirestoreCollection } from "../models/types/collection.type";
import FirestoreService from "./firebase/firestore.service";

class IngredientService {
    private firestoreService: FirestoreService;
    private collectionName: FirestoreCollection;

    constructor() {
        this.firestoreService = new FirestoreService();
        this.collectionName = 'ingredients';
    }

    async get(refresh?: boolean): Promise<Ingredient[]> {
        if (refresh) localStorage.removeItem('firestore_ingredients_is_used_==_true');
        return await this.firestoreService.queryDocs(
            this.collectionName,
            [{ field: 'is_used', operator: '==', value: true }],
            true
        );
    }

    async getIds(): Promise<string[]> {
        return await this.firestoreService.getAllIds(this.collectionName, false);
    }

    async getById(id: string): Promise<Ingredient | null> {
        return (await this.firestoreService.getDocById(this.collectionName, id)) as Ingredient | null;
    }

    async update(ingredient: Ingredient): Promise<Ingredient | null> {
        return await this.firestoreService.updateDoc(this.collectionName, ingredient.id, ingredient)
    }

    async add(ingredient: Ingredient): Promise<string | null> {
        try {
            ingredient.id = ingredient.name.english.trim().replaceAll(/[^\w\s]/g, '');
            ingredient.keywords = {
                english: ingredient.name.english.split(' '),
                spanish: ingredient.name.spanish.split(' ')
            }
            return await this.firestoreService.addDoc(this.collectionName, ingredient, ingredient.id);
        } catch(error: any) {
            console.error(`Lo siento, no se pudo agregar (${error?.message})`);
            return null;
        }
    }

    async delete(id: string): Promise<void> {
        try {
            await this.firestoreService.deleteDoc(this.collectionName, id);
        }
        catch(error: any) {
            console.log(error);
            alert('Lo sentimos, no se pudo eliminar, pida soporte');
        }
    }
}

export const ingredientService = new IngredientService();