import { app } from '.';
import { Auth, getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut, User } from 'firebase/auth';

export default class AuthService {
    private auth: Auth;
    private googleProvider: GoogleAuthProvider;

    constructor() {
        this.auth = getAuth(app);
        this.googleProvider = new GoogleAuthProvider();
    }

    async signInWithGoogle(): Promise<User | null> {
        try {
          const result = await signInWithPopup(this.auth, this.googleProvider);
          return result.user;
        } catch (error: any) {
          console.error('Error al autenticar con Google:', error?.message);
          return null;
        }
      }
    
    async signOut(): Promise<void> {
    try {
        await signOut(this.auth);
        console.log('Sesión cerrada');
    } catch (error) {
        console.error('Error al cerrar sesión:', error);
    }
    }

    onAuthStateChanged(callback: (user: User | null) => void): () => void {
        return onAuthStateChanged(this.auth, callback);
    }

    async getCurrentUser(): Promise<User | null> {
        return new Promise((resolve) => {
          const unsubscribe = this.onAuthStateChanged((user) => {
            resolve(user);
            unsubscribe();
          });
        });
    }
}