// RecipeContext.tsx
import React, { createContext, useContext, useState } from 'react';

interface RecipeContextProps {
    recipeIds: string[];
    setRecipeIds: React.Dispatch<React.SetStateAction<string[]>>;
}

const RecipeContext = createContext<RecipeContextProps | undefined>(undefined);

export const RecipeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [recipeIds, setRecipeIds] = useState<string[]>([]);

    return (
        <RecipeContext.Provider value={{ recipeIds, setRecipeIds }}>
            {children}
        </RecipeContext.Provider>
    );
};

export const useRecipeContext = () => {
    const context = useContext(RecipeContext);
    if (!context) {
        throw new Error('useRecipeContext must be used within a RecipeProvider');
    }
    return context;
};
