import AuthService from "./services/firebase/auth.service";
import { redirect } from 'react-router-dom';

const authService = new AuthService();

export async function authLoader(callbackLoader?: Function | undefined, callbackId?: string) {
    const user = await authService.getCurrentUser();
    if (!user) throw redirect('/login');
    if (callbackLoader) return callbackLoader(callbackId);
    return { user }
}

export async function loginLoader() {
    const user = await authService.getCurrentUser();
    if (user) throw redirect('/');
    return null
}