import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { authLoader, loginLoader } from "./auth.loader";
import { homeLoader } from "./pages/products/index.loader";
import React, { Suspense } from "react";
import { addProductLoader } from "./pages/products/add/index.loader";
import { RecipeProvider } from "./pages/products/index.context";
import { IngredientLoader } from "./pages/ingredients/index.loader";
import { IngredientProvider } from "./pages/ingredients/index.context";

const ProductsPage = React.lazy(() => import("./pages/products"));
const AddProductPage = React.lazy(() => import("./pages/products/add"));
const LoginPage = React.lazy(() => import("./pages/login"));
const IngredientsPage = React.lazy(() => import("./pages/ingredients"));
const AddIngredientPage = React.lazy(() => import("./pages/ingredients/add"));
const Layout = React.lazy(() => import("./layouts"));


const router = createBrowserRouter([
    {
        path: '/',
        element: <Suspense><Layout /></Suspense>,
        loader: () => authLoader(),
        children: [
            {
                path: '/',
                element: <Suspense><RecipeProvider><ProductsPage /></RecipeProvider></Suspense>,
                loader: () => homeLoader(),
            },
            {
                path: '/productos',
                element: <Suspense><RecipeProvider><ProductsPage /></RecipeProvider></Suspense>,
                loader: () => homeLoader(),
            },
            {
                path: '/productos/agregar',
                element: <Suspense><AddProductPage /></Suspense>,
                loader: () => addProductLoader(),
            },
            {
                path: '/ingredientes',
                element: <Suspense><IngredientProvider><IngredientsPage /></IngredientProvider></Suspense>,
                loader: () => IngredientLoader(),
            },
            {
                path: '/ingredientes/agregar',
                element: <Suspense><AddIngredientPage /></Suspense>,
                loader: () => IngredientLoader(),
            },
        ]
    },
    {
        path: 'login',
        element: <Suspense><LoginPage /></Suspense>,
        loader: () => loginLoader()
    },
]);

const AppRouter: React.FC = () => {
    return <RouterProvider router={router} />;
};

export default AppRouter;