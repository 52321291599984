// RecipeContext.tsx
import React, { createContext, useContext, useState } from 'react';

interface RecipeContextProps {
    ingredientIds: string[];
    setIngredientIds: React.Dispatch<React.SetStateAction<string[]>>;
}

const IngredientContext = createContext<RecipeContextProps | undefined>(undefined);

export const IngredientProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [ingredientIds, setIngredientIds] = useState<string[]>([]);

    return (
        <IngredientContext.Provider value={{ ingredientIds: ingredientIds, setIngredientIds: setIngredientIds }}>
            {children}
        </IngredientContext.Provider>
    );
};

export const useIngredientContext = () => {
    const context = useContext(IngredientContext);
    if (!context) {
        throw new Error('useRecipeContext must be used within a RecipeProvider');
    }
    return context;
};
