import Recipe from "../models/recipe.model";
import { FirestoreCollection } from "../models/types/collection.type";
import FirestoreService from "./firebase/firestore.service";

class RecipesService {
    private firestoreService: FirestoreService;
    private collectionName: FirestoreCollection;

    constructor() {
        this.firestoreService = new FirestoreService();
        this.collectionName = 'recipes';
    }

    async get(): Promise<Recipe[]> {
        try {
            return await this.firestoreService.getAllDocs(this.collectionName);
        } catch(error: any) {
            console.error(`lo siento no se pudieron cargar las recetas: ${error?.message}`)
            return [];
        }
    }

    async getIds(): Promise<string[]> {
        return await this.firestoreService.getAllIds(this.collectionName, false);
    }

    async getById(id: string): Promise<Recipe | null> {
        return (await this.firestoreService.getDocById(this.collectionName, id)) as Recipe | null;
    }

    async update(ingredient: Recipe): Promise<Recipe | null> {
        return await this.firestoreService.updateDoc(this.collectionName, ingredient.id, ingredient)
    }

    async delete(id: string): Promise<void> {
        try {
            await this.firestoreService.deleteDoc(this.collectionName, id);
        }
        catch(error: any) {
            console.log(error);
            alert('Lo sentimos, no se pudo eliminar, pida soporte');
        }
    }

    async add(ingredient: Recipe): Promise<string | null> {
        try {
            ingredient.id = ingredient.name?.english || '';
            return await this.firestoreService.addDoc(this.collectionName, ingredient, ingredient.id);
        } catch(error: any) {
            console.error(`Lo siento, no se pudo agregar (${error?.message})`);
            return null;
        }
    }
}

export const recipesService = new RecipesService();