import { app } from '.';
import { 
    addDoc, 
    collection, 
    deleteDoc, 
    doc, 
    getDoc, 
    getDocs, 
    getFirestore, 
    query, 
    setDoc, 
    updateDoc, 
    where, 
    WhereFilterOp
} from 'firebase/firestore';
import { FirestoreCollection } from '../../models/types/collection.type';

const firestore = getFirestore(app);

export default class FirestoreService {
    private cacheKeyPrefix = 'firestore_';
  
    // Function to add a new document
    async addDoc(collectionName: FirestoreCollection, data: any, id?: string) {
        try {
            const colRef = collection(firestore, collectionName);
            let docRef;

            if (id) {
                // Use the provided ID as the document ID
                docRef = doc(firestore, collectionName, id);
                await setDoc(docRef, data); // Use setDoc instead of addDoc
            } else {
                // Generate a new document ID
                docRef = await addDoc(colRef, data);
            }

            return docRef.id;
        } catch (error) {
            console.error('Error adding document:', error);
            throw new Error('Failed to add document');
        }
    }
  
    // Function to get a document by its ID
    async getDocById(collectionName: FirestoreCollection, id: string) {
        try {
          const docRef = doc(firestore, collectionName, id);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            return { id: docSnap.id, ...docSnap.data() };
          } else {
            return null;
          }
        } catch (error) {
          console.error('Error getting document by ID:', error);
          throw new Error('Failed to get document by ID');
        }
    }
  
    // Function to get all documents in a collection
    async getAllDocs(collectionName: FirestoreCollection, useCache: boolean = true) {
        try {
            const today = new Date().toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format
            const cacheKey = `${this.cacheKeyPrefix}${collectionName}`;
            
            if (useCache) {
                // Try to retrieve cached data from localStorage
                const cachedData = localStorage.getItem(cacheKey);
                if (cachedData) {
                    const parsedData = JSON.parse(cachedData);
                    if (parsedData.date === today) {
                        console.log('Returning cached data');
                        return parsedData.data;
                    }
                }
            }

            // Fetch data from Firestore
            const colRef = collection(firestore, collectionName);
            const querySnapshot = await getDocs(colRef);
            const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // Store result in localStorage with today's date
            localStorage.setItem(cacheKey, JSON.stringify({ data, date: today }));

            return data;
        } catch (error) {
            console.error('Error getting all documents:', error);
            throw new Error('Failed to get all documents');
        }
    }

    // Function to get all document ids from collection
    async getAllIds(collectionName: FirestoreCollection, useCache: boolean = true): Promise<string[]> {
        try {
            const today = new Date().toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format
            const cacheKey = `${this.cacheKeyPrefix}${collectionName}_ids`;

            if (useCache) {
                // Try to retrieve cached data from localStorage
                const cachedData = localStorage.getItem(cacheKey);
                if (cachedData) {
                    const parsedData = JSON.parse(cachedData);
                    if (parsedData.date === today) {
                        console.log('Returning cached ID data');
                        return parsedData.data;
                    }
                }
            }

            // Fetch data from Firestore
            const colRef = collection(firestore, collectionName);
            const querySnapshot = await getDocs(colRef);
            const ids = querySnapshot.docs.map(doc => doc.id); // Only retrieve the IDs

            // Store result in localStorage with today's date
            localStorage.setItem(cacheKey, JSON.stringify({ data: ids, date: today }));

            return ids;
        } catch (error) {
            console.error('Error getting all document IDs:', error);
            return [];
        }
    }
  
    // Function to update a document by its ID
    async updateDoc(collectionName: FirestoreCollection, id: string, data: any) {
        try {
          const docRef = doc(firestore, collectionName, id);
          await updateDoc(docRef, data);
          return { id, ...data };
        } catch (error) {
          console.error('Error updating document:', error);
          throw new Error('Failed to update document');
        }
    }
  
    // Function to delete a document by its ID
    async deleteDoc(collectionName: FirestoreCollection, id: string) {
        try {
          const docRef = doc(firestore, collectionName, id);
          await deleteDoc(docRef);
          return id;
        } catch (error) {
          console.error('Error deleting document:', error);
          throw new Error('Failed to delete document');
        }
    }
  
    // Function to query documents based on conditions
    async queryDocs(
        collectionName: FirestoreCollection,
        conditions: Array<{ field: string, operator: WhereFilterOp, value: any }>,
        useCache: boolean = true
    ) {
        try {
            const today = new Date().toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format

            // Create a cache key based on the collection name and conditions
            const conditionsKey = conditions.map(c => `${c.field}_${c.operator}_${c.value}`).join('_');
            const cacheKey = `${this.cacheKeyPrefix}${collectionName}_${conditionsKey}`;

            if (useCache) {
                // Try to retrieve cached data from localStorage
                const cachedData = localStorage.getItem(cacheKey);
                if (cachedData) {
                    const parsedData = JSON.parse(cachedData);
                    if (parsedData.date === today) {
                        console.log('Returning cached data');
                        return parsedData.data;
                    }
                }
            }

            // Start with the initial query
            const colRef = collection(firestore, collectionName);
            let q = query(colRef);

            // Add conditions to the query
            conditions.forEach(condition => q = query(q, where(condition.field, condition.operator, condition.value)));

            const querySnapshot = await getDocs(q);
            const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // Store result in localStorage with today's date
            localStorage.setItem(cacheKey, JSON.stringify({ data, date: today }));

            return data;
        } catch (error) {
            console.error('Error querying documents:', error);
            throw new Error('Failed to query documents');
        }
    }
}